.emoji-mart-scroll::-webkit-scrollbar {
    @apply w-0 bg-transparent;
}

.tiptap {
    @apply w-full h-full overflow-y-auto p-2 box-border;
}

.tiptap p.is-empty::before {
    @apply text-gray-400;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

.tiptap ul,
.tiptap ol {
    @apply pl-6 m-0;
}

.tiptap ul {
    @apply list-disc;
    ul {
        list-style-type: circle;

        ul {
            list-style-type: square;
        }
    }
}

.tiptap ol {
    @apply list-decimal;
    ol {
        list-style-type: upper-roman;

        ol {
            list-style-type: lower-alpha;
        }
    }
}

.tiptap li {
    @apply mb-1;
}

.tiptap a {
    @apply text-blue-600 underline cursor-pointer;
}

.tiptap a:hover {
    @apply text-blue-700 underline cursor-pointer;
}

.tiptap em {
    @apply bg-transparent
}

.editor-link {
    @apply text-blue-600 underline cursor-pointer inline p-0 #{!important};;
}
