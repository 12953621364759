@import '../../nxto/src/styles';

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');

.Garamond {
  font-family: 'EB Garamond', serif !important;
}

  .item-box {
    @apply mt-1 flex flex-col w-full hover:bg-gray-100 cursor-pointer rounded-md p-2;
  }


